<template>
  <div>
    <label v-if="label" :for="id" class="de-form-input-text-label">{{ label }}</label>

    <prime-textarea
      :id="id"
      v-model="value"
      v-bind="$attrs"
      :placeholder="textareaPlaceholder"
      :maxlength="maxlength"
      :minlength="minlength"
      class="de-form-input-text"
      :class="{ 'is-error': !!errorMessage || isError, 'tw-resize-none': !resizable }"
      v-on="validationListeners"
    />

    <div v-if="errorMessage" class="de-form-error">
      {{ errorMessage }}
    </div>

    <div
      class="tw-flex tw-items-center tw-justify-between tw-text-300 tw-leading-400 tw-text-primary-300 tw-mt-1"
    >
      <p v-if="hintMessage">{{ hintMessage }}</p>

      <p v-if="isWordLimitShown">
        <span :class="wordLimitClass">
          {{ value.length }}
        </span>

        <span>/{{ maxlength || minlength }}</span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';
import { computed } from 'vue';

// defineOptions({
//   inheritAttrs: false,
// });

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: null,
  },
  hintMessage: {
    type: String,
    default: null,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  maxlength: {
    type: Number,
    default: null,
  },
  minlength: {
    type: Number,
    default: null,
  },
  showWordLimit: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  resizable: {
    type: Boolean,
    default: false,
  },
});

const { errorMessage, value, handleChange, handleBlur } = useField(() => props.id, undefined, {
  validateOnValueUpdate: false,
});

const validationListeners = {
  blur: (evt) => handleBlur(evt, true),
  change: handleChange,
  input: (evt) => handleChange(evt, !!errorMessage.value),
};

const isWordLimitShown = computed(() => {
  return props.showWordLimit && (props.maxlength || props.minlength) && value.value;
});

const wordLimitClass = computed(() => {
  if (
    (props.maxlength && value.value?.length <= props.maxlength) ||
    (props.minlength && value.value?.length >= props.minlength)
  ) {
    return 'tw-text-success-500';
  }

  return 'tw-text-accent-500';
});

const textareaPlaceholder = computed(() => props.placeholder);
</script>
